<template>
  <div>
    <div class="menu-filters" id="menufilters" v-on:click="togglemobilemenu()">
      <h3>Filtra aquí tus resultados</h3>
      <i class="fas fa-filter" id="togglemobilefilters"></i>
    </div>
    <div :class="'menu-container ' + togglemobilemenuvar" id="menu_container">
      <div class="wrapper-mobile-menu">

        <div class="container-fluid content-wrapper">
          <div class="filters-title">
            <p class="filter_menu_cleanfilters_btn" id="filter_menu_cleanfilters_btn" @click="borrarFiltros">Borrar
              filtros</p>
            <h5 class="filter_menu_title">Filtra tus resultados</h5>
            <i class="fas fa-times" id="toggle_close_filters_menu" v-on:click="togglemobilemenu()"></i>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="accordion" id="filtrosmoviles">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="filtroestados">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseestados" aria-expanded="false" aria-controls="collapseestados">
                      Ubicación
                    </button>
                  </h2>
                  <div id="collapseestados" class="accordion-collapse collapse" aria-labelledby="filtroestados"
                    data-bs-parent="#filtrosmoviles">
                    <div class="accordion-body">
                      <button class="btn-sm btn-light btn-filtro-movil" v-for="estado in estados" :key="estado.estado"
                        v-text="estado.estado + ' (' + estado.cant_estado + ')'"
                        v-on:click="nuevoEstado(estado.id_estado)">

                      </button>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="filtromarcas">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapsemarcas" aria-expanded="true" aria-controls="collapsemarcas">
                      Marca
                    </button>
                  </h2>
                  <div id="collapsemarcas" class="accordion-collapse collapse show" aria-labelledby="filtromarcas"
                    data-bs-parent="#filtrosmoviles">
                    <div class="accordion-body">
                      <button v-for="marca in marcas" :key="marca.id" class="btn-sm btn-light btn-filtro-movil"
                        v-text="marca.marca + ' (' + marca.num_marcas + ')'"
                        @click="nuevaMarca(marca.id)"></button>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="filtroanio">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseanio" aria-expanded="false" aria-controls="collapseanio">
                      Año
                    </button>
                  </h2>
                  <div id="collapseanio" class="accordion-collapse collapse" aria-labelledby="filtroanio"
                    data-bs-parent="#filtrosmoviles">
                    <div class="accordion-body">
                      <button v-for="anio in anios" :key="anio.id_anio" v-text="anio.anio + ' (' + anio.num_anios + ')'"
                        @click="nuevoAnio(anio.anio)" class="btn-sm btn-light btn-filtro-movil"></button>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="precio">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseprecio" aria-expanded="false" aria-controls="collapseprecio">
                      Precio
                    </button>
                  </h2>
                  <div id="collapseprecio" class="accordion-collapse collapse" aria-labelledby="precio"
                    data-bs-parent="#filtrosmoviles">
                    <div class="accordion-body">
                      <div class="row">
                        <div class="container" style="padding-left: 10%; padding-right: 10%">
                          <vue-slider v-bind="options" :min="precios[0].precio_min" :max="precios[0].precio_max"
                            v-model="preciosSelect" style="margin-button: 0px"></vue-slider>
                          <table style="width: 100%">
                            <tr>
                              <td>
                                <h5 class="sidebar_text-left">
                                  {{ preciosSelect[0] | currency("$", 0) }}
                                </h5>
                              </td>
                              <td class="sidebar_text-right">
                                <h5 style="float-right">
                                  {{ preciosSelect[1] | currency("$", 0) }}
                                </h5>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="filtrocarroceria">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapsecarroceria" aria-expanded="false" aria-controls="collapsecarroceria">
                      Tipo
                    </button>
                  </h2>
                  <div id="collapsecarroceria" class="accordion-collapse collapse" aria-labelledby="filtrocarroceria"
                    data-bs-parent="#filtrosmoviles">
                    <div class="accordion-body">
                      <div class="container">
                        <div class="row">
                          <div class="col-4" style="text-align: center; cursor: pointer"
                            v-for="carroceria in carrocerias" :key="carroceria.id_carroceria"
                            @click="nuevaCarroceria(carroceria.id_carroceria)">
                            <img class="sidebar-carstyle" :src="cdn + carroceria.imgid" v-if="cdnenabled == '1' && carroceria.imgid != null && carroceria.imgid != ''"/>
                            <img class="sidebar-carstyle" :src="'/images/' + carroceria.url_img_carroceria" v-else/>

                            <p class="sidebar-item-title" v-text="carroceria.carroceria">
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btnmostrarrescont">
          <button class="btn btn-dark" v-on:click="gotosite('/seminuevos?' + url)">Mostrar resultados</button>
        </div>
      </div>
    </div>
    <section>

      <div class="container" style="margin-top: 3rem;">
        <div class="row">
          <div class="col-12">
            <h2 class="subtitle">¿Qué <span style="font-weight: 800;"> tipo de auto</span> buscas?</h2>
          </div>
        </div>
        <div class="row">
          <div
            :class="['col-2 col-md-2 col-xl px-md-3 px-1 filter-cartype', ((carroceriaseleccionadav2.includes(carroceria.id_carroceria)) ? 'active' : ''), (carroceriaseleccionadav2.length == 0 ? 'active' : '')]"
            v-for="carroceria in catalogocarrocerias" :key="carroceria.id_carroceria">
            <div class="filter-cartype-btn" @click="nuevaCarroceria(carroceria.id_carroceria)">
              <img :src="cdn + carroceria.imgid+'/public'" v-if="cdnenabled == '1' && carroceria.imgid != null && carroceria.imgid != ''"/>
              <img :src="'/images/' + carroceria.url_img_carroceria" v-else/>
              <p class="d-none d-lg-block">{{ carroceria.carroceria }}</p>
            </div>
            <p class="d-lg-none">{{ carroceria.carroceria }}</p>
          </div>

        </div>

      </div>

      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="accordion main-brands" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
                    <h2 class="subtitle">¿Prefieres alguna <span style="font-weight: 800;"> marca</span>?</h2>
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body accordion-marcas">
                    <div class="container-fluid container-marcas">
                      <div class="row" v-if="!carroceriaseleccionadav2.length">
                        <div
                          :class="['col-2 col-lg-1 px-1 px-md-3 filter-brand', (marcasseleccionadas.includes(marcax.id) ? 'active' : ''), (marcasseleccionadas.length == 0 ? 'active' : '')]"
                          v-for="marcax in catalogomarcas" :key="marcax.id" @click="nuevaMarca(marcax.id)">
                          <div class="filter-brand-btn">
                            <img :src="cdn+marcax.imgid+'/public'" :alt="marcax.marca.toLowerCase()" v-if="cdnenabled=='1' && marcax.imgid != null && marcax.imgid != ''"/>
                            <img :src="'/images/logos/' + marcax.marca.toLowerCase() + '.png'" :alt="marcax.marca.toLowerCase()" v-else/>
                          </div>
                          <p>{{ marcax.marca }}</p>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div
                          :class="['col-2 col-lg-1 filter-brand', (marcasseleccionadas.includes(marcax.id) ? 'active' : ''), (marcasseleccionadas.length == 0 ? 'active' : '')]"
                          v-for="marcax in marcas" :key="marcax.id" @click="nuevaMarca(marcax.id)">
                          <div class="filter-brand-btn">
                            <img :src="cdn+marcax.imgid+'/public'" :alt="marcax.marca.toLowerCase()" v-if="cdnenabled=='1' && marcax.imgid != null && marcax.imgid != ''"/>
                            <img :src="'/images/logos/' + marcax.marca.toLowerCase() + '.png'" :alt="marcax.marca.toLowerCase()" v-else/>
                          </div>
                          <p>{{ marcax.marca }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <h2 class="subtitle" style="font-size: 2rem;">¡Quiero <span style="font-weight: 800;"> vender mi
                        auto</span>!</h2>
                  </button>
                </h2>
                <div id="collapseTwo" :class="['accordion-collapse collapse', (venderauto != 'off' ? 'show' : '')]"
                  aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <div class="px-0 container-fluid px-md-3">
                      <div class="row">
                        <div class="col-lg-6">
                          <img :src="cdn+tomas+'/public'" alt="" style="width: 100%;" v-if="cdnenabled == '1'" />
                          <img src="/images/assets/tomas-c2a-1.jpg" alt="" style="width: 100%;" v-else />
                        </div>

                        <div class="col-lg-6" style="display: none;">

                          <div class="row datos-tomas">
                            <div class="col-12">
                              <input type="text" class="form-control" id="name" placeholder="Nombre completo" required
                                name="name" v-model="nombre">
                            </div>
                            <div class="col-6">
                              <input type="tel" class="form-control" id="tel" placeholder="Teléfono a 10 dígitos"
                                required name="tel" v-model="telefono">
                            </div>
                            <div class="col-6">

                              <input type="email" class="form-control" id="email" placeholder="Correo electrónico"
                                v-model="email" required @focusout="validateEmail()" name="email">
                              <div id="validateemail" class="form-validation-msg" v-if="validEmail == 0">
                                Por favor, ingresa una dirección de correo válida.
                              </div>
                            </div>

                            <div class="col-12">
                              <select class="form-select" aria-label="Default select example"
                                v-model="marcaSeleccionadaCotizador" @change="listarModelosCotizador()" name="marca">
                                <option selected>Marca</option>
                                <option :value="marca.marca" v-for="marca in marcasCotizador" :key="marca.marca"
                                  v-text="marca.marcatitulo"></option>

                              </select>
                            </div>
                            <div class="col-12">
                              <select class="form-select" aria-label="Default select example"
                                v-model="modeloSeleccionadoCotizador" @change="listarAniosCotizador()" name="modelo">
                                <option selected>Modelo</option>
                                <option :value="modelo.modelo" v-for="modelo in modelosCotizador" :key="modelo.modelo"
                                  v-text="modelo.modelotitulo"></option>
                              </select>
                            </div>
                            <div class="col-12">
                              <select class="form-select" aria-label="Default select example"
                                v-model="anioSeleccionadoCotizador" name="anio">
                                <option selected>Año</option>
                                <option :value="anio.anio" v-for="anio in aniosCotizador" :key="anio.anio"
                                  v-text="anio.anio"></option>
                              </select>
                            </div>
                            <div class="text-center col-12">
                              <button class="btn-calltoaction" @click="submitButtonPressed()"> <strong><i>
                                    COTIZAR</i></strong></button>
                            </div>
                          </div>

                        </div>
                        <div class="col-lg-6">
                            <div class="row datos-tomas">
                                <div class="col-12">
                                <a href="/vender" class="btn-calltoaction"> <strong><i>
                                        COTIZAR</i></strong></a>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="container" style="margin-top: 4rem;">
        <div class="row">
          <div class="text-center col-12 titulo-inventario" id="inventario">
            <img src="/images/apmarker.svg" alt="" style="height: 3rem; width: auto;">
            <h2 class="subtitle" style="font-size: 2rem; margin-left: 0.5rem;">Nuestro <span style="font-weight: 800;">
                inventario</span></h2>
          </div>
        </div>
      </div>
      <div class="container-fluid" id="nuestroinventario" style="position: relative">

        <div class="row">
          <div class=" col-xl-3 d-none d-xl-block left-panel animate__animated animate__fadeIn">
            <div class="accordion" id="accordionSidebar">

              <div class="accordion-item" v-if="catalogocarrocerias.length">
                <h2 class="accordion-header" id="headingTipo">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTipo" aria-expanded="false" aria-controls="collapseTipo">
                    Tipo
                  </button>
                </h2>
                <div id="collapseTipo" class="accordion-collapse collapse show" aria-labelledby="headingTipo"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">

                    <div class="container">
                      <div class="row">
                        <div
                          :class="['col-4 sidebar-img-btn', ((carroceriaseleccionadav2.includes(carroceria.id_carroceria)) ? 'active' : ''), (carroceriaseleccionadav2.length == 0 ? 'active' : '')]"
                          style="text-align: center; cursor: pointer" v-for="carroceria in catalogocarrocerias"
                          :key="carroceria.id_carroceria" @click="nuevaCarroceria(carroceria.id_carroceria)">
                          <img class="sidebar-carstyle" :src="cdn + carroceria.imgid+'/public'" v-if="cdnenabled == '1' && carroceria.imgid != null && carroceria.imgid != ''"/>
                          <img class="sidebar-carstyle" :src="'/images/' + carroceria.url_img_carroceria" v-else/>
                          <p class="sidebar-item-title" v-text="carroceria.carroceria"></p>
                        </div>
                      </div>
                    </div>
                    <p class="reset_cat_btn" v-on:click="nuevaCarroceria()" v-if="carroceriaseleccionadav2.length >= 1">
                      Todos los tipos
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-if="marcas.length">
                <h2 class="accordion-header" id="headingMarcas">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseMarcas" aria-expanded="false" aria-controls="collapseMarcas">
                    Marcas
                  </button>
                </h2>
                <div id="collapseMarcas" class="accordion-collapse collapse" aria-labelledby="headingMarcas"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="nuevaMarca()" v-if="marcas.length == 1">
                      Todas las marcas
                    </p>
                    <button :class="['boton_left_bar', (marcasseleccionadas.includes(marca.id) ? 'active' : '')]"
                      v-for="marca in marcas" :key="marca.id" v-text="marca.marca + ' (' + marca.num_marcas + ')'"
                      @click="nuevaMarca(marca.id)">

                    </button>
                  </div>
                </div>
              </div>

              <div class="accordion-item" v-if="modelos.length">
                <h2 class="accordion-header" id="headingModelos">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseModelos" aria-expanded="false" aria-controls="collapseModelos">
                    Modelo
                  </button>
                </h2>
                <div id="collapseModelos" class="accordion-collapse collapse" aria-labelledby="headingModelos"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="nuevoModelo()" v-if="modelos.length == 1">
                      Todos los modelos
                    </p>
                    <button class="boton_left_bar" v-for="modelo in modelos" :key="modelo.id_modelo"
                      v-text="modelo.modelo + ' (' + modelo.num_modelos + ')'" @click="nuevoModelo(modelo.id_modelo)">

                    </button>
                  </div>
                </div>
              </div>

              <div class="accordion-item" v-if="anios.length">
                <h2 class="accordion-header" id="headingAnios">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseAnios" aria-expanded="false" aria-controls="collapseAnios">
                    Año
                  </button>
                </h2>
                <div id="collapseAnios" class="accordion-collapse collapse" aria-labelledby="headingAnios"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="nuevoAnio()" v-if="anios.length == 1">
                      Todos los años
                    </p>
                    <button class="boton_left_bar" v-for="anio in anios" :key="anio.id_anio"
                      v-text="anio.anio + ' (' + anio.num_anios + ')'" @click="nuevoAnio(anio.anio)">

                    </button>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingPrecio">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePrecio" aria-expanded="false" aria-controls="collapsePrecio">
                    Precio
                  </button>
                </h2>
                <div id="collapsePrecio" class="accordion-collapse collapse" aria-labelledby="headingPrecio"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="resetearPrecios()" v-if="
                      preciosSelect[0] != precios[0].precio_min ||
                      preciosSelect[1] != precios[0].precio_max
                    ">
                      Todos los precios
                    </p>
                    <div class="container" style="padding-left: 10%; padding-right: 10%">
                      <vue-slider v-bind="options" :min="precios[0].precio_min" :max="precios[0].precio_max"
                        v-model="preciosSelect" style="margin-button: 0px"></vue-slider>
                      <table style="width: 100%">
                        <tr>
                          <td>
                            <h5 class="sidebar_text-left">
                              {{ preciosSelect[0] | currency("$", 0) }}
                            </h5>
                          </td>
                          <td class="sidebar_text-right">
                            <h5 style="float-right">
                              {{ preciosSelect[1] | currency("$", 0) }}
                            </h5>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingKilometraje">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseKilometraje" aria-expanded="false" aria-controls="collapseKilometraje">
                    Kilometraje
                  </button>
                </h2>
                <div id="collapseKilometraje" class="accordion-collapse collapse" aria-labelledby="headingKilometraje"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="resetearKm()" v-if="
                      kilometrajeSelect[0] != kilometrajes[0].kilometraje_min ||
                      kilometrajeSelect[1] != kilometrajes[0].kilometraje_max
                    ">
                      Todos los kilometrajes
                    </p>
                    <div class="container" style="padding-left: 10%; padding-right: 10%">
                      <vue-slider v-bind="optionskm" :min="kilometrajes[0].kilometraje_min"
                        :max="kilometrajes[0].kilometraje_max" v-model="kilometrajeSelect" style="margin-button: 0px">
                      </vue-slider>
                      <table style="width: 100%">
                        <tr>
                          <td>
                            <h5 class="sidebar_text-left">
                              {{
                                  kilometrajeSelect[0]
                                  | currency(" Km.", 0, {
                                    symbolOnLeft: false,
                                  })
                              }}
                            </h5>
                          </td>
                          <td class="sidebar_text-right">
                            <h5 style="float-right">
                              {{
                                  kilometrajeSelect[1]
                                  | currency(" Km.", 0, {
                                    symbolOnLeft: false,
                                  })
                              }}
                            </h5>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>


              <div class="accordion-item" v-if="colores2.length">
                <h2 class="accordion-header" id="headingColores">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseColores" aria-expanded="false" aria-controls="collapseColores">
                    Color
                  </button>
                </h2>
                <div id="collapseColores" class="accordion-collapse collapse" aria-labelledby="headingColores"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="nuevoColorExt" v-if="colores2.length == 1">
                      Todos los colores
                    </p>
                    <div class="container">
                      <div class="row">
                        <div class="col-4 hvr-grow" v-for="color_ext in colores2" :key="color_ext.id_color"
                          style="text-align: center; margin-top: 1em; cursor: pointer"
                          v-on:click="nuevoColorExt(color_ext.color_ext)">
                          <div class="color" :style="'background: #' + color_ext.hex_ext + ';'"></div>
                          <p v-text="color_ext.color_ext" class="color_titulo"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item" v-if="estados.length">
                <h2 class="accordion-header" id="headingEstados">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#estadosSidebar" aria-expanded="true" aria-controls="estadosSidebar">
                    Estados
                  </button>
                </h2>
                <div id="estadosSidebar" class="accordion-collapse collapse" aria-labelledby="headingEstados"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="nuevoEstado()" v-if="estados.length == 1">
                      Todos los estados
                    </p>
                    <button class="boton_left_bar" v-for="estado in estados" :key="estado.estado"
                      v-text="estado.estado + ' (' + estado.cant_estado + ')'"
                      v-on:click="nuevoEstado(estado.id_estado)">

                    </button>
                  </div>
                </div>
              </div>
              <div class="accordion-item" v-if="agencias.length">
                <h2 class="accordion-header" id="headingAgencias">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseAgencias" aria-expanded="false" aria-controls="collapseAgencias">
                    Agencias
                  </button>
                </h2>
                <div id="collapseAgencias" class="accordion-collapse collapse" aria-labelledby="headingAgencias"
                  data-bs-parent="#accordionSidebar">
                  <div class="accordion-body">
                    <p class="reset_cat_btn" v-on:click="nuevaAgencia()" v-if="agencias.length == 1">
                      Todos las agencias
                    </p>
                    <button class="boton_left_bar" v-for="agencia in agencias" :key="agencia.id_agencia" v-text="
                      agencia.agencia + ' (' + agencia.cant_agencias + ')'
                    " v-on:click="nuevaAgencia(agencia.id_agencia)">

                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn_borrar_filtros" @click="borrarFiltros">
              BORRAR FILTROS
            </button>

          </div>
          <div class="col-xl-9 col-12" id="autos">
            <div class="container container-fluid-lg container_listing">
              <div class="row">
                <div class="px-4 col-lg-4 col-6" v-for="anuncio in arrayAnuncios" :key="anuncio.quiter_id">
                  <a :class="(anuncio.id_badge == 9) ? 'row anuncio descesp' : 'row anuncio'" :href="'/auto?id=' + anuncio.quiter_id">
                    <!-- BEGIN DESKTOP -->

                    <div class="px-0 col-12">
                      <div class="row">
                        <div class="col-12 garantiaextendida" style="position: relative" v-if="anuncio.agencia_id == 2">
                            <h3>14 meses de garantía</h3>
                        </div>
                        <div class="col-12" style="position: relative">
                          <img src="/images/badge_hoy2.png" alt="" class="badgehoy" v-if="anuncio.id_badge == 9">


                          <div class="imagen-anuncio">
                            <div class="badgejun23div">
                                <img src="/images/oferta0623.png" alt="" class="badgejun23" v-if="anuncio.agencia_id == 2">
                            </div>
                            <img :src="cdn+anuncio.cf_id+'/public'" :alt="anuncio.marca +' ' +anuncio.modelo +' ' +anuncio.version" v-if="anuncio.cf_id !== null && cdnenabled == '1' && anuncio.cf_id != null && anuncio.cf_id != ''"/>
                            <img :src="'/imgs/fotos/' + anuncio.anuncio_id +'/'+ anuncio.thumbnail_url" :alt="anuncio.marca +' ' +anuncio.modelo +' ' +anuncio.version" v-else-if="anuncio.thumbnail_url !== null"/>
                            <img :src="cdn+proximamente+'/public'" v-else-if="cdnenabled == '1'" />
                            <img src="/proximamente.jpg" v-else />
                          </div>

                          <div class="btn-fotos-originales" v-if="
                            anuncio.fotos_originales &&
                            anuncio.fotos_originales != ''
                          ">
                            <a :href="
                              '/storage/fotosoriginales/' +
                              anuncio.anuncio_id +
                              '.pdf'
                            " target="_blank" style="z-index: 1">
                              <button class="fas fa-camera" v-tooltip:top="
                                '<span>Descargar fotografias en alta resolución</span>'
                              "></button>
                            </a>
                          </div>
                          <a class="btn-video" style="
                              position: absolute;
                              bottom: 4px;
                              right: 18px;
                              z-index: 1;
                            " v-if="
                              anuncio.video_url != '' &&
                              anuncio.video_url != null
                            " data-fancybox :href="
                              'https://autoprice.com.mx/storage/video/' +
                              anuncio.anuncio_id +
                              '.mp4'
                            ">
                            <button class="fas fa-play" style="
                                border: none;
                                background-color: transparent;

                                font-size: 1.4rem;
                              " v-tooltip:top="'<span>Video</span>'"></button>
                          </a>

                        </div>
                        <div class="col-12" v-on:click="linkear(anuncio.quiter_id)">
                          <div v-if="
                            anuncio.badge != '' &&
                            anuncio.badge != null &&
                            anuncio.badge != 'Ninguno'
                          " class="badge-titulo" v-text="anuncio.badge" :style="
                            'background-color: #' +
                            anuncio.color_badge +
                            '; text-align:center;'
                          ">

                          </div>
                        </div>
                        <div class="col-12 info_cell" v-on:click="linkear(anuncio.quiter_id)">
                          <div class="row">
                            <div class="col-12">
                              <h5 class="link-auto">
                                <span class="marca">{{ anuncio.marca }}</span> {{ anuncio.modelo }} {{ anuncio.version
                                }}
                              </h5>
                              <p class="anuncio-atributos">
                                {{ anuncio.anio }} {{ anuncio.kilometraje | currency("", 0) }}Km.

                              </p>

                            </div>

                          </div>

                          <p class="promotexto" v-text="promotexto"  v-if="anuncio.id_badge == 9"></p>
                        </div>

                      </div>

                    </div>
                    <div class="px-0 col-12 preciosanuncio">
                      <div class="row" style="padding-left: 1rem; padding-right: 1rem;">
                        <div class="px-0 col-6 px-md-3">
                          <p class="preciocontadotitulo">Precio de contado:</p>
                          <p v-if="!anuncio.precio_oferta" class="precio_listado">{{ anuncio.precio_venta |
                              currency("$", 0)
                          }}</p>
                          <div v-if="anuncio.precio_oferta" class="precio_oferta_wrapper">
                            <p v-if="anuncio.precio_oferta" class="precio_original">{{ anuncio.precio_venta |
                                currency("$", 0)
                            }}</p>
                            <p v-if="anuncio.precio_oferta" class="precio_oferta">{{ anuncio.precio_oferta |
                                currency("$",
                                  0)
                            }}</p>


                          </div>


                        </div>
                        <div class="px-0 col-6 px-md-3">
                          <p class="preciocontadotitulo mensualidad">Desde:</p>
                          <p class="precio_listado mensualidad">{{ anuncio.precio_venta / 99.18 | currency("$",
                              0)
                          }} <br>mensual</p>
                        </div>
                      </div>
                    </div>


                    <!-- END DESKTOP -->
                  </a>
                </div>
              </div>
              <div class="row m-border" v-if="!arrayAnuncios.length && firstLoad == '1'">
                <div class="ml-auto mr-auto text-center col-xl-5 col-sm-6 col-10">
                  <h3 class="mt-5 mb-3">¿No encuentras lo que buscas?</h3>
                  <button type="button" class="px-1 py-3 btn btn-primary"
                    style="width: 100%; font-size: 2em; font-weight: 700" data-toggle="modal" data-target="#modal10">
                    ¡Pídelo!
                  </button>

                  <h4 class="mt-5 mb-3">
                    Encontramos el mejor auto para tí.
                    <br />¡Fácil, rápido y seguro!
                  </h4>
                  <p style="font-size: 1.3em">o también</p>
                  <button type="button" class="px-1 py-3 btn btn-primary"
                    style="width: 100%; font-size: 2em; font-weight: 700" @click="borrarFiltros()">
                    Comenzar de nuevo
                  </button>
                </div>
              </div>
              <div class="b-items__pagination">
                <div class="b-items__pagination-main" data-wow-delay="0.5s" style="padding-left: 2em">
                  <a href="javascript:;" class="m-left" v-if="pagination.current_page > 1" style="text-decoration: none"
                    @click="cambiarPagina(current_page - 1)">
                    <span class="fa fa-angle-left" style="font-size: 1.5em"></span>
                  </a>
                  <span v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'm-active' : '']"
                    style="padding: 0px 0.5em; text-decoration: none">
                    <a href="javascript:;" @click="cambiarPagina(page)">
                      <span style="font-size: 1.5em">{{ page }}</span>
                    </a>
                  </span>
                  <a href="javascript:;" class="m-right" v-if="pagination.current_page < pagination.last_page"
                    style="text-decoration: none" @click="cambiarPagina(pagination.current_page + 1)">
                    <span class="fa fa-angle-right" style="font-size: 1.5em"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";

import vueSlider from "vue-slider-component";

Vue.directive("tooltip", function (el, binding) {
  $(el).tooltip({
    title: binding.value,
    placement: binding.arg,
    trigger: "hover",
    html: true,
  });
});

import jQuery from "jquery";
//global declaration of jquery
global.jQuery = jQuery;
global.$ = jQuery;

$(() => {
  $("#app").tooltip({
    selector: '[data-toggle="tooltip"]',
  });
});

$(function () {
  $('[data-toggle="popover"]').popover();
});

export default {
  props: ["cdn", "venderauto", "promotexto", "cdnenabled", "proximamente", "tomas"],
  data() {
    return {
      marcasCotizador: [],
      marcaSeleccionadaCotizador: "Marca",
      modelosCotizador: [],
      modeloSeleccionadoCotizador: "Modelo",
      aniosCotizador: [],
      anioSeleccionadoCotizador: "Año",
      validEmail: 3,
      marcasseleccionadas: [],
      url_marcas: "",
      url_carrocerias: "",
      carroceriaseleccionadav2: [],
      catalogocarrocerias: [],
      catalogomarcas: [],
      togglemobilemenuvar: "",
      firstLoad: "",
      paginaurl: "",
      colorextseleccionado: "",
      agenciaseleccionada: "",
      carroceriaseleccionada: "",
      estadoseleccionado: "",
      versionseleccionada: "",
      parametro: "",
      parametros: "",
      soldoutcarid: "",
      existingUrl: "",
      existingSidebarUrl: "",
      marcasmodal: [],
      pagina: "",
      contactname: "",
      contactemail: "",
      contactphone: "",
      contactmessage: "",
      urlmodal: "/sidebar?",
      carroceriaModal: "",
      arrayCarroceriasModal: [],
      arrayModelosModal: [],
      arrayMarcasModal: [],
      marcaseleccionadamodal: "",
      modeloseleccionadomodal: "",
      preciomodal: "Rango de precio",
      estadoseleccionadomodal: "",
      pagination: [],
      nombre: "",
      email: "",
      aniodesde: "",
      aniohasta: "",
      preciosSelectModal: [80000, 1000000],
      titulo: "",
      anio: "",
      anioseleccionado: "",
      kilometraje: 0,

      precio: 0,
      videoUrl: "",
      tituloBadge: "",
      colorBadge: "",
      arrayAnuncios: [],
      agencias: [],
      agencia: this.location,
      carrocerias: [],
      carroceria: "",
      marcas: [],
      marca: "",
      marcaseleccionada: "",
      modelos: [],
      modelo: "",
      modelo2: [],
      modeloseleccionado: "",
      precios: [],
      preciosSelect: [20, 500],
      precioMax: 0,
      precioMin: 0,
      precioMaxTemp: 0,
      precioMinTemp: 0,
      anios: [],
      anio2: [],
      kilometrajes: [],

      kilometrajeSelect: [],

      colores: [],
      color: "",
      colores2: [],
      colores3: [],
      color2: "",
      telefono: "",

      estados: [],

      url: "",
      url2: "",
      arraySidebar: [],
      anio2: [],
      anio3: 0,
      badgeseleccionado: "",
      badges: [],
      losquiero: [],
      nombremailing: "",
      correomailing: "",
      mensaje: "",
      pagination: {
        total: 0,
        current_page: 0,
        per_page: 0,
        last_page: 0,
        from: 0,
        to: 0,
      },
      offset: 3,
      options: {
        width: "100%",
        height: 8,
        dotSize: 20,
        dotColor: "#000000",
        min: 0,
        max: 200,
        disabled: false,
        show: true,
        tooltip: "null",
        tooltipDir: ["bottom", "top"],
        piecewise: false,
        style: {
          marginBottom: "5px",
        },
        bgStyle: {
          backgroundColor: "#fff",
          boxShadow: "inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)",
        },
        sliderStyle: [
          {
            backgroundColor: "#fb7300",
          },
          {
            backgroundColor: "#fb7300",
          },
        ],
        tooltipStyle: [
          {
            backgroundColor: "#f05b72",
            borderColor: "#f05b72",
          },
          {
            backgroundColor: "#f05b72",
            borderColor: "#f05b72",
          },
        ],
        processStyle: {
          backgroundImage: "-webkit-linear-gradient(left, #444444, #8c8484)",
        },
        piecewiseStyle: {
          backgroundColor: "#fff",
        },
      },
      optionskm: {
        width: "100%",
        height: 8,
        dotSize: 20,
        dotColor: "#000000",
        min: 0,
        max: 200,
        disabled: false,
        show: true,
        tooltip: "null",
        tooltipDir: ["bottom", "top"],
        piecewise: false,
        style: {
          marginBottom: "5px",
        },
        bgStyle: {
          backgroundColor: "#fff",
          boxShadow: "inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)",
        },
        sliderStyle: [
          {
            backgroundColor: "#fb7300",
          },
          {
            backgroundColor: "#fb7300",
          },
        ],
        tooltipStyle: [
          {
            backgroundColor: "#f05b72",
            borderColor: "#f05b72",
          },
          {
            backgroundColor: "#f05b72",
            borderColor: "#f05b72",
          },
        ],
        processStyle: {
          backgroundImage: "-webkit-linear-gradient(left, #444444, #8c8484)",
        },
        piecewiseStyle: {
          backgroundColor: "#fff",
        },
      },
      optionsmodal: {
        width: "100%",
        height: 8,
        dotSize: 20,
        dotColor: "#000000",
        min: 0,
        max: 200,
        disabled: false,
        show: true,
        tooltip: "null",
        tooltipDir: ["bottom", "top"],
        piecewise: false,
        style: {
          marginBottom: "30px",
        },
        bgStyle: {
          backgroundColor: "#fff",
          boxShadow: "inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)",
        },
        sliderStyle: [
          {
            backgroundColor: '#fb7300',
          },
          {
            backgroundColor: '#fb7300',
          },
        ],
        tooltipStyle: [
          {
            backgroundColor: '#f05b72',
            borderColor: "#f05b72",
          },
          {
            backgroundColor: "#f05b72",
            borderColor: "#f05b72",
          },
        ],
        processStyle: {
          backgroundImage: "-webkit-linear-gradient(left, #444444, #8c8484)",
        },
        piecewiseStyle: {
          backgroundColor: "#fff",
        },
      },
    };
  },
  computed: {
    isActived: function () {
      return this.pagination.current_page;
    },
    //Calcula los elementos de la paginación
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }

      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }

      var to = from + this.offset * 2;
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }

      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
  },
  methods: {
    submitButtonPressed(e) {
      var me = this;
      var palabrasNombre = me.nombre.split(" ");
      if (palabrasNombre.length < 2) {
        this.$swal(
          {
            title: 'Error!',
            text: 'Por favor escribe tu nombre completo',
            icon: 'error',
            confirmButtonText: 'Ok'
          }
        );
      } else if (me.telefono.length < 10) {
        this.$swal(
          {
            title: 'Error!',
            text: 'Tu número de teléfono parece inválido. Por favor escríbe los 10 dígitos de tu número celular sin usar espacios.',
            icon: 'error',
            confirmButtonText: 'Ok'
          }
        );
      }
      else if (me.validEmail == 0) {
        this.$swal(
          {
            title: 'Error!',
            text: 'El correo que escribiste parece ser inválido. Por favor intenta con otra dirección.',
            icon: 'error',
            confirmButtonText: 'Ok'
          }
        );
      }
      else if (me.marcaSeleccionadaCotizador == "Marca" || me.modeloSeleccionadoCotizador == "Modelo" || me.anioSeleccionadoCotizador == "Año") {
        this.$swal(
          {
            title: 'Error!',
            text: 'Confirma la marca, modelo y año de tu auto.',
            icon: 'error',
            confirmButtonText: 'Ok'
          }
        );
      }
      else {
        axios.post('/cotizador/step-5b', {
          nombre: me.nombre,
          telefono: me.telefono,
          correo: me.email,
          marca: me.marcaSeleccionadaCotizador,
          modelo: me.modeloSeleccionadoCotizador,
          anio: me.anioSeleccionadoCotizador
        },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
              'X-Requested-With': 'XMLHttpRequest'
            }
          }

        )
          .then(function (response) {
            window.location.href = "/cotizador/step-5?id=" + response.data;
          });
      }
    },
    listarMarcasCotizador() {
      var me = this;

      axios.get('/cotizador/listarMarcas')
        .then(function (response) {
          var data = response.data;
          me.marcasCotizador = data;
        });

    },
    listarModelosCotizador() {
      var me = this;
      me.aniosCotizador = [];
      if (me.marcaSeleccionadaCotizador != "Marca") {
        axios.get('/cotizador/listarModelos?marca=' + me.marcaSeleccionadaCotizador)
          .then(function (response) {
            var data = response.data;
            me.modelosCotizador = data;
            me.modeloSeleccionadoCotizador = me.modelosCotizador[0].modelo.toString();
            me.listarAniosCotizador();
          });
      };

      if (me.marcaSeleccionadaCotizador == "Marca") {
        me.modelosCotizador = [];
        me.aniosCotizador = [];
        me.modeloSeleccionadoCotizador == "Modelo";
        me.anioSeleccionadoCotizador == "Año";
      }
    },
    listarAniosCotizador() {
      var me = this;
      if (me.marcaSeleccionadaCotizador != "Marca" && me.modeloSeleccionadoCotizador != "Modelo") {
        axios.get('/cotizador/listarAnios?marca=' + me.marcaSeleccionadaCotizador + '&modelo=' + me.modeloSeleccionadoCotizador.toLowerCase())
          .then(function (response) {
            var data = response.data;
            me.aniosCotizador = data;
            me.anioSeleccionadoCotizador = me.aniosCotizador[0].anio;
          });
      }
      if (me.modeloSeleccionadoCotizador == "Modelo") {
        me.aniosCotizador = [];
        me.anioSeleccionadoCotizador == "Año";
      }
    },
    validateEmail() {
      var me = this;
      axios.post('/leads/validateemail', {
        email: me.email
      })
        .then(function (response) {
          var data = response.data;
          console.log(data.score);
          console.log(data.reason);
          console.log(data);
          if (data.score != null && parseInt(data.score) > 85) {
            me.validEmail = 1;
          } else {
            me.validEmail = 0;
          }
        });
    },
    togglemobilemenu() {
      if (this.togglemobilemenuvar == '') {
        this.togglemobilemenuvar = 'active';
      } else {
        this.togglemobilemenuvar = '';
      }
    },
    onChange: function (event) {
      return event.target.value;
    },
    linkear(id) {
      // window.location.href = "/auto?id=" + id;
    },
    resetearPrecios() {
      var me = this;
      me.preciosSelect = [];
      me.preciosSelect = [me.precios[0].precio_min, me.precios[0].precio_max];
      me.constructURL();
    },
    resetearKm() {
      var me = this;
      me.kilometraje = "";
      me.kilometrajeSelect = [];
      me.kilometrajeSelect = [
        me.kilometrajes[0].kilometraje_min,
        me.kilometrajes[0].kilometraje_max,
      ];
      me.constructURL();
    },
    nuevoEstado(valor) {
      var me = this;
      me.pagination.current_page = "";
      me.paginaurl = "";
      if (me.estados.length == 1) {
        me.estadoseleccionado = "";
        me.agenciaseleccionada = "";
        me.borrarFiltros();
      } else {
        me.estadoseleccionado = valor;
        me.constructURL();
      }
    },
    nuevaAgencia(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (me.agencias.length == 1) {
        me.agenciaseleccionada = "";
        me.carroceriaseleccionada = "";
        me.marcaseleccionada = "";
        me.modeloseleccionado = "";
        me.anioseleccionado = "";

        me.colorextseleccionado = "";
        var url = "/sidebar";
        axios.get(url).then(function (response) {
          var respuesta = response.data;
          me.agencias = respuesta.agencias;
          me.carrocerias = respuesta.carrocerias;
          me.marcas = respuesta.marcas;
          me.modelos = respuesta.modelos;
          me.versiones = respuesta.versiones;
          me.badges = respuesta.badges;
          me.precios = respuesta.precios2;
          me.estados = respuesta.estados;
          me.preciosSelect = [
            me.precios[0].precio_min,
            me.precios[0].precio_max,
          ];
          me.kilometrajeSelect = [
            respuesta.kilometrajes[0].kilometraje_min,
            respuesta.kilometrajes[0].kilometraje_max,
          ];
          me.anios = respuesta.anios;
          me.kilometraje = respuesta.kilometrajes.kilometraje_max;

          me.colores2 = respuesta.coloresExt;

          me.constructURL();
        });
      } else {
        me.agenciaseleccionada = valor;
        me.constructURL();
      }
    },

    nuevaCarroceria(valor) {
      var me = this;
      me.pagination.current_page = "";
      me.url_carrocerias = "";
      $('#collapseOne').collapse();
      if (valor) {
        if (me.carroceriaseleccionadav2.includes(valor)) {
          var index = me.carroceriaseleccionadav2.indexOf(valor);
          me.carroceriaseleccionadav2.splice(index, 1);
        } else {
          me.carroceriaseleccionadav2.push(valor);
        };

        me.marcasseleccionadas = [];
        me.url_carrocerias = me.carroceriaseleccionadav2.toString();
        me.constructURL();
      } else {

        me.carroceriaseleccionadav2 = [];
        me.url_carrocerias = "";
        me.constructURL();
      }
    },

    nuevaMarca(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (valor) {
        if (me.marcasseleccionadas.includes(valor)) {
          var index = me.marcasseleccionadas.indexOf(valor);
          me.marcasseleccionadas.splice(index, 1);
        } else {
          me.marcasseleccionadas.push(valor);
        };
        me.url_marcas = me.marcasseleccionadas.toString();
        me.constructURL();
      } else {
        me.marcasseleccionadas = [];
        me.modeloseleccionado = "";
        me.anioseleccionado = "";

        me.colorextseleccionado = "";
        me.url_marcas = "";
        me.constructURL();
      }
    },
    nuevaMarca_bak(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (me.marcas.length == 1) {
        me.marcas = [];
        me.marcaseleccionada = "";
        me.modeloseleccionado = "";
        me.anioseleccionado = "";

        me.colorextseleccionado = "";
        me.constructURL();
      } else {
        me.marcaseleccionada = valor;
        me.constructURL();
      }
    },
    nuevoModelo(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (me.modeloseleccionado == valor) {
        me.modeloseleccionado = "";
        //me.modeloseleccionado = valor;
        me.constructURL();
      } else {
        me.modeloseleccionado = valor;
        me.constructURL();
      }
    },
    nuevoAnio(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (me.anioseleccionado == valor) {
        me.anioseleccionado = "";
        //me.anioseleccionado = valor;
        me.constructURL();
      } else {
        me.anioseleccionado = valor;
        me.constructURL();
      }
    },

    nuevoBadge(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (me.badges.length == 1) {
        me.badgeseleccionado = "";
        me.badges = [];
        //me.badgeseleccionado = valor;
        me.constructURL();
      } else {
        me.badgeseleccionado = valor;
        me.constructURL();
      }
    },
    nuevoColorExt(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (me.colores2.length == 1) {
        me.colorextseleccionado = "";
        me.colores2 = [];
        //me.colorextseleccionado = valor;
        me.constructURL();
      } else {
        me.colorextseleccionado = valor;
        me.constructURL();
      }
    },
    nuevoPrecioSeleccionado(valor) {
      var me = this;
      me.pagination.current_page = "";
      if (me.preciosSelect[0] != me.precios[0].precio_min) {
        me.constructURL();
      }
      if (me.preciosSelect[1] != me.precios[0].precio_max) {
        me.constructURL();
      }
    },
    nuevoKilometrajeSeleccionado() {
      var me = this;
      me.pagination.current_page = "";
      if (me.kilometrajeSelect[0] != me.kilometrajes[0].kilometraje_min) {
        me.constructURL();
      }
      if (me.kilometrajeSelect[1] != me.kilometrajes[0].kilometraje_max) {
        me.constructURL();
      }
    },
    gotoNuestroInventario() {
      var elmnt = document.getElementById("autos");
      elmnt.scrollIntoView();
    },
    cambiarPagina(page) {
      let me = this;
      if (me.pagination.current_page == 0 || me.pagination.current_page == '0') {
        me.pagination.current_page = '2';
      } else {
        me.pagination.current_page = page;
      }
      me.gotoNuestroInventario();
      me.constructURL();
    },
    registrarMailing() {
      var me = this;
      if (me.nombremailing && me.correomailing) {
        axios
          .post("/registrarMailing", {
            nombre: me.nombremailing,
            correo: me.correomailing,
          })
          .then(function (response) {
            swal(
              "¡Registrado!",
              "Has sido registrado correctamente en nuestra base de datos.",
              "success"
            );
          });
      } else {
        swal(
          "Un momento...",
          "Necesitas escribir tu nombre y dirección de correo para registrarte",
          "error"
        );
      }
    },
    enviarmailcontacto() {
      var me = this;
      axios
        .post("/send/contacto", {
          nombre: me.contactname,
          correo: me.contactemail,
          telefono: me.contactphone,
          mensaje: me.contactmessage,
          tema: "General",
        })
        .then(function (response) {
          swal(
            "¡Listo!",
            "Hemos recibido tu mensaje. En breve nos comunicaremos contigo. ¡Gracias!",
            "success"
          );
        });
    },
    enviarmailauto(anuncio) {
      var me = this;
      axios
        .post("/send/contacto", {
          nombre: me.contactname,
          correo: me.contactemail,
          telefono: me.contactphone,
          mensaje:
            "Busco un auto como este: <a href='https://autoprice.com.mx/auto?id=" +
            anuncio +
            "'>Click aquí</a>",
          tema: "Busco un auto",
        })
        .then(function (response) {
          swal(
            "¡Listo!",
            "Hemos recibido tu mensaje. En breve nos comunicaremos contigo. ¡Gracias!",
            "success"
          );
        });
    },
    pideunauto() {
      var me = this;
      axios
        .post("/send/pedirunauto", {
          marca: me.marcaseleccionadamodal,
          modelo: me.modeloseleccionadomodal,
          precio: me.preciomodal,
          aniodesde: me.aniodesde,
          aniohasta: me.aniohasta,
          estado: me.estadoseleccionadomodal,
          nombre: me.nombre,
          email: me.email,
          tel: me.telefono,
          mensaje: me.mensaje,
        })
        .then(function (response) {
          swal(
            "¡Listo!",
            "En breve un ejecutivo se comunicará contigo.",
            "success"
          );
        });
    },
    gotositeblank(producturl) {
      window.open(producturl, "_blank");
    },
    gotosite(producturl) {
      //window.location.href(producturl);
      window.open(producturl, "_self");
    },


    borrarFiltros() {
      var me = this;
      me.marcasseleccionadas = [],
        me.url_marcas = "",
        me.url_carrocerias = "",
        me.carroceriaseleccionadav2 = [],
        me.agencias = [];
      me.carrocerias = [];
      me.marcas = [];
      me.modelos = [];
      me.versiones = [];
      me.badges = [];
      me.precios = [];
      me.estados = [];
      me.anios = [];
      me.kilometrajes = [];
      me.kilometrajeSelect = [];
      me.preciosSelect = [];

      me.colores2 = [];

      me.anio3 = me.year;
      me.agencia = me.location;
      me.carroceria = me.car_type;
      me.marca = me.brand;
      me.modelo = me.model;
      me.version = me.version;
      me.kilometraje = "";
      me.estado = me.state;
      me.colores = [];
      me.colores3 = [];
      me.marcaseleccionada = "";
      me.badgeseleccionado = "";
      me.anioseleccionado = "";

      me.estadoseleccionado = "";
      me.modeloseleccionado = "";
      me.versionseleccionada = "";
      me.carroceriaseleccionada = "";
      me.colorextseleccionado = "";
      var url = "/sidebar";
      axios.get(url).then(function (response) {
        var respuesta = response.data;
        me.agencias = respuesta.agencias;
        me.carrocerias = respuesta.carrocerias;
        me.marcas = respuesta.marcas;
        me.modelos = respuesta.modelos;
        me.versiones = respuesta.versiones;
        me.badges = respuesta.badges;
        me.precios = respuesta.precios2;
        me.estados = respuesta.estados;

        me.preciosSelect = [me.precios[0].precio_min, me.precios[0].precio_max];

        me.kilometrajeSelect = [
          respuesta.kilometrajes[0].kilometraje_min,
          respuesta.kilometrajes[0].kilometraje_max,
        ];

        me.anios = respuesta.anios;
        me.kilometraje = respuesta.kilometrajes.kilometraje_max;

        me.colores2 = respuesta.coloresExt;


        me.agencia = "";
        me.carroceria = "";
        me.constructURL();
      });
    },

    listarAutos(params, page2) {
      var me = this;
      var url = "";

      if (me.paginaurl != "") {
        url = "/anuncios?status=1&" + "page=" + me.paginaurl + "&" + params;
      } else {
        url = "/anuncios?status=1&" + "page=1&" + params;
      }

      axios
        .get(url)
        .then(function (response) {
          var respuesta = response.data;
          me.arrayAnuncios = respuesta.anuncios.data;
          me.pagination = respuesta.pagination;
          me.firstLoad = "1";
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    setUrlVar(url) {
      axios.post("/anuncios/setautovar", {
        autovar: url,
      });
    },
    getUrlVar() {
      var me = this;
      axios.get("/anuncios/getautovar").then(function (response) {
        me.existingUrl = response.data.autovar;
      });
    },
    setSidebarUrlVar(info) {
      axios.post("/anuncios/setsidebarvar", {
        sidebarvar: info,
      });
    },
    getSidebarUrlVar() {
      var me = this;
      axios.get("/anuncios/getsidebarvar").then(function (response) {
        me.existingSidebarUrl = response.data.sidebarvar;
      });
    },

    GetURLParameter(sParam) {
      var me = this;
      var parametro = "";
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split("&");
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] == sParam) {
          parametro = sParameterName[1];
        }
      }
      return parametro;
    },
    listarSidebar() {
      let me = this;

      axios.get('/sidebar').then(function (response) {
        var respuesta = response.data;
        me.catalogocarrocerias = respuesta.carrocerias;
        me.catalogomarcas = respuesta.marcas;
      });

      var url = "/sidebar?" + me.url;

      axios
        .get(url)
        .then(function (response) {
          var respuesta = response.data;
          me.agencias = respuesta.agencias;
          me.carrocerias = respuesta.carrocerias;

          me.marcas = respuesta.marcas;
          me.modelos = respuesta.modelos;
          me.versiones = respuesta.versiones;
          me.badges = respuesta.badges;
          me.precios = respuesta.precios2;
          me.estados = respuesta.estados;
          if (me.preciosSelect[0] == 20) {
            me.preciosSelect = [
              respuesta.precio_min_select,
              respuesta.precio_max_select
            ];
          }

          me.anios = respuesta.anios;

          me.kilometrajes = respuesta.kilometrajes;
          me.kilometraje = me.kilometrajes.kilometraje_max;
          if (!me.kilometrajeSelect[0]) {
            me.kilometrajeSelect = [
              me.kilometrajes[0].kilometraje_min,
              me.kilometrajes[0].kilometraje_max,
            ];
          }


          me.colores2 = respuesta.coloresExt;

          me.listarAutos(me.url, "1");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    listarmodelosmodal() {
      var me = this;
      me.urlmodal = "/sidebar?";
      if (me.marcaseleccionadamodal && me.marcaseleccionadamodal != "") {
        me.urlmodal += "marcas=" + me.marcaseleccionadamodal + "&";
      }
      if (me.carroceriaModal && me.carroceriaModal != "") {
        me.urlmodal += "carroceria=" + me.carroceriaModal + "&";
      }
      axios
        .get("/carrocerias")
        .then(function (response) {
          var respuesta1 = response.data;
          me.arrayCarroceriasModal = respuesta1.carrocerias;
          axios
            .get(me.urlmodal)
            .then(function (response) {
              var respuesta = response.data;

              me.arrayModelosModal = respuesta.modelos;
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    listarmarcasmodal() {
      var me = this;
      axios
        .get("/marcas")
        .then(function (response) {
          var respuesta = response.data;
          me.arrayMarcasModal = respuesta.marcas;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    constructURL() {
      let me = this;

      me.url = "?";
      if (me.estadoseleccionado && me.estadoseleccionado != "") {
        me.url += "estado=" + me.estadoseleccionado + "&";
      }
      if (me.agenciaseleccionada && me.agenciaseleccionada != "") {
        me.url += "agencia=" + me.agenciaseleccionada + "&";
      }
      if (me.carroceriaseleccionadav2.length) {
        me.url += "carroceria=" + me.url_carrocerias + "&";
      }
      if (me.marcasseleccionadas.length) {
        me.url += "marcas=" + me.url_marcas + "&";
      }

      if (me.modeloseleccionado && me.modeloseleccionado != "") {
        me.url += "modelo=" + me.modeloseleccionado + "&";
      }

      if (me.versionseleccionada && me.versionseleccionada != "") {
        me.url += "version=" + me.versionseleccionada + "&";
      }

      if (me.badgeseleccionado && me.badgeseleccionado != "") {
        me.url += "badge=" + me.badgeseleccionado + "&";
      }

      if (me.kilometrajeSelect[0] > -1) {
        me.url += "kilometraje_min=" + me.kilometrajeSelect[0] + "&";
      }
      if (me.kilometrajeSelect[1] > -1) {
        me.url += "kilometraje_max=" + me.kilometrajeSelect[1] + "&";
      }
      if (me.anioseleccionado && me.anioseleccionado != "") {
        me.url += "anio=" + me.anioseleccionado + "&";
      }

      if (me.pagination.current_page && me.pagination.current_page != "") {
        me.url += "page=" + me.pagination.current_page + "&";
      }



      if (me.colorextseleccionado && me.colorextseleccionado != "") {
        me.url += "colores=" + me.colorextseleccionado + "&";
      }
      if (me.preciosSelect[0] != 20) {
        me.url += "precio_min=" + me.preciosSelect[0] + "&";
      }
      if (me.preciosSelect[1] != 500) {
        me.url += "precio_max=" + me.preciosSelect[1] + "&";
      }

      if (me.url != "?") {
        window.history.pushState({ urlPath: me.url }, "", me.url);
      }

      me.GetUrlParameters();
    },
    GetUrlParameters() {
      var me = this;
      var sPageURL = window.location.search.substring(1);
      me.url = sPageURL.toString();
      me.listarSidebar();
      me.paginaurl = me.GetURLParameter("page");
      me.estadoseleccionado = me.GetURLParameter("estado");
      me.agenciaseleccionada = me.GetURLParameter("agencia");

      me.carroceriaseleccionadav2 = me.GetURLParameter("carroceria").split(',').filter(element => element != 0).map(str => Number(str));
      me.marcasseleccionadas = me.GetURLParameter("marcas").split(',').filter(element => element != 0).map(str => Number(str));

      me.modeloseleccionado = me.GetURLParameter("modelo");
      me.anioseleccionado = me.GetURLParameter("anio");

      me.colorextseleccionado = me.GetURLParameter("color_ext");
      me.kilometrajeSelect[0] = me.GetURLParameter("kilometraje_min");
      me.kilometrajeSelect[1] = me.GetURLParameter("kilometraje_max");
      if (me.GetURLParameter("precio_min")) {
        me.preciosSelect[0] = me.GetURLParameter("precio_min");
      }
      if (me.GetURLParameter("precio_max")) {
        me.preciosSelect[1] = me.GetURLParameter("precio_max");
      }
    },
  },
  watch: {
    preciosSelect: _.debounce(function (valor) {
      var me = this;
      me.nuevoPrecioSeleccionado(valor);
    }, 500),
    kilometrajeSelect: _.debounce(function (valor) {
      var me = this;
      me.nuevoKilometrajeSeleccionado(valor);
    }, 500),
    marcaseleccionada: function () {
      // NI IDEA DE PORQUÉ ESTÁ ESTO AQUÍ
      // this.constructURL();
    }
  },
  mounted() {
    var me = this;
    console.log('Ver 3.1');
    window.onpopstate = function (event) {
      if (event.state) {
        me.GetUrlParameters();
      } else {
        me.GetUrlParameters();
      }
    };
    me.constructURL();
    me.listarmarcasmodal();
    this.listarmodelosmodal();
    this.listarMarcasCotizador();
    if (me.venderauto == "off") {
      $('#collapseTwo').collapse('hide');
      console.log('esconder');
    };
  },

  components: {
    vueSlider,
  },
};
</script>
