<template>
    <div class="row">
        <div class="col-md-12 text-center" v-if="!precio">
            <h4
                style="
                            margin-top: 1em;
                            font-weight: 300;
                            font-size: 2em;
                            "
            >
                Escribe la cantidad a financiar:
            </h4>
            <input
                type="number"
                name="cantidadmanual"
                v-model="precio_venta"
                style="    font-family: Montserrat;
                font-size: 3rem;
                line-height: 1.4;
                font-weight: 400;
                font-style: normal;
                border-top: none;
                border-right: none;
                border-left: none;
                border-bottom: 3px solid #cccccc;
                color: #535353;
                text-align: center;
                max-width: 100%;"
            />
        </div>
        <div
            class="col-md-12 px-5"
            style="
                        text-align: center;
                        margin-bottom: 4em;
                        "
        >
            <!-- !! -->

            <h4
                style="
                            margin-top: 1em;
                            font-weight: 300;
                            font-size: 2em;
                            "
            >
                Mensualidad:
            </h4>
            <h2 class="mensualidad" v-if="plan == 12">
                {{ doce_meses | currency("$", 0) }}
                M.N.
                <sup>*</sup>
            </h2>
            <h2 class="mensualidad" v-if="plan == 24">
                {{ veinticuatro_meses | currency("$", 0) }}
                M.N.
                <sup>*</sup>
            </h2>
            <h2 class="mensualidad" v-if="plan == 36">
                {{ treintayseis_meses | currency("$", 0) }}
                M.N.
                <sup>*</sup>
            </h2>
            <h2 class="mensualidad" v-if="plan == 48">
                {{ cuarentayocho_meses | currency("$", 0) }}
                M.N.
                <sup>*</sup>
            </h2>
            <h2 class="mensualidad" v-if="plan == 60">
                {{ sesenta_meses | currency("$", 0) }}
                M.N.
                <sup>*</sup>
            </h2>
            <h4>
                {{ plan }}
                meses
            </h4>
            <h4 style="margin-top: 0em; padding-bottom: 3em; font-size: 18px;">
                Enganche:
                {{ enganche | currency("$", 0) }}
                M.N.
            </h4>


            <div
                class="b-items__aside-main-body-item"
                style="margin-bottom: 0px"
            >
                <vue-slider
                    style = "height: 8px;"
                    :tooltip-formatter="formatter1"
                    :tooltip="'always'"
                    :data="data"
                    :marks="false"
                    v-model="plan"
                    :tooltip-placement="'top'"
                ></vue-slider>
                <h3></h3>
            </div>
            <h5 class="cotizador-slider-txt">
                Selecciona el
                <strong>plazo</strong>
                que más te guste.
            </h5>

            <div
                class="b-items__aside-main-body-item"
                style="margin-bottom: 0px; margin-top: 4em"
            >
                <vue-slider
                    style = "height: 8px;"
                    :tooltip-formatter="formatter2"
                    :tooltip="'always'"
                    :data="dataenganche"
                    :marks="false"
                    v-model="porcentajeEnganche"
                    :tooltip-placement="'top'"
                ></vue-slider>
                <h3></h3>
            </div>
            <h5 class="cotizador-slider-txt">
                Selecciona el
                <strong>enganche</strong>
                que más te agrade.
            </h5>

            <div class="row" style="margin-top: 3em; display: none;">
                <div class="col-md-12">
                    <table class="resultados-cotizador">
                        <tr style="display:none;">
                            <td>
                                <h4 class="resultados-cotizador-titulo">Precio del auto</h4>
                            </td>
                            <td>
                                <h4  class="resultados-cotizador-resultado">
                                    {{
                                        precio_venta | currency("$", 0)
                                    }}
                                    M.N.
                                    <sup>*</sup>
                                </h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4 class="resultados-cotizador-titulo">Monto del crédito</h4>
                            </td>
                            <td>
                                <h4  class="resultados-cotizador-resultado">
                                    {{
                                        monto_financiar
                                            | currency("$", 0)
                                    }}
                                    M.N.
                                    <sup>*</sup>
                                </h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h4 class="resultados-cotizador-titulo">Tasa de interés</h4>
                            </td>
                            <td>
                                <h4  class="resultados-cotizador-resultado">
                                    {{ tasadeinteres * 100 }}%
                                    <sup>*</sup>
                                </h4>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 text-center">
            <!--<a href="#" data-izimodal-open="#modal" data-izimodal-transitionin="fadeInDown"> -->
                <button class="button btn-lg btn-meinteresa" v-if="availability == 1" onclick="registrarLead()"> Me interesa </button>
                <button class="button btn-lg btn-meinteresa" v-else disabled> Me interesa </button>
            <!--</a> -->
        </div>
        <div class="col-md-12">
            <p class="disclaimer">
                <sup>*</sup> {{disclaimer}}
            </p>
        </div>
    </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/default.css'

import { CurrencyInput } from "vue-currency-input";
import Vue2Filters from "vue2-filters";

Vue.use(Vue2Filters);
export default {
    components: {
        VueSlider,
        CurrencyInput
    },
    props: ["precio","tasa","disclaimer","availability"],
    data() {
        return {
            porcentajeEnganche: "25%",
            formatter1: "{value} meses",
            formatter2: "{value}",
            slidervalue: "36",
            data: ["12", "24", "36", "48"],
            dataenganche: ["10%", "20%", "40%", "60%", "80%"],
            enganchereco: 0,
            enganche: 0,
            plan: "36",
            monto_financiar: 10,
            doce_meses: 10,
            veinticuatro_meses: 10,
            treintayseis_meses: 10,
            cuarentayocho_meses: 10,
            sesenta_meses: 10,
            tasadeinteres: this.tasa / 100,
            precio_venta: this.precio
        };
    },
    methods: {
        fireCotizador() {
            var me = this;
            me.dataenganche = ["10%", "25%", "45%", "65%", "85%"];

            //me.options.min = parseInt(me.precio_venta) * 0.1;
            //me.options.max = parseInt(me.precio_venta) * 0.60;
            me.enganchereco = parseInt(me.precio_venta) * 0.3;
            if (me.enganche == 0) {
                me.enganche = me.precio_venta * 0.3;
            }
            me.monto_financiar = me.precio_venta - me.enganche;
            me.doce_meses = (me.monto_financiar * (1 + me.tasadeinteres)) / 12;
            me.veinticuatro_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 2)) / 24;
            me.treintayseis_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 3)) / 36;
            me.cuarentayocho_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 4)) / 48;
            me.sesenta_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 5)) / 60;

            var mensualidadmaxima24 =
                (me.precio_venta -
                    me.precio_venta * 0.4 +
                    (me.precio_venta - me.precio_venta * 0.4) *
                        (me.tasadeinteres * 2)) /
                24;
            var mensualidadminima60 =
                (me.precio_venta -
                    me.precio_venta * 0.3 +
                    (me.precio_venta - me.precio_venta * 0.3) *
                        (me.tasadeinteres * 5)) /
                60;
        }
    },
    computed: {
        max24: function() {
            return this.precio_venta;
        },
        min60: function() {
            return (
                this.precio_venta -
                this.precio_venta * 0.6 +
                (this.precio_venta -
                    this.precio_venta * 0.6 +
                    me.tasadeinteres * 5) /
                    60
            );
        }
    },
    watch: {
        enganche: function(valor) {
            var me = this;
            me.monto_financiar = me.precio_venta - valor;
            me.doce_meses = (me.monto_financiar * (1 + me.tasadeinteres)) / 12;
            me.veinticuatro_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 2)) / 24;
            me.treintayseis_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 3)) / 36;
            me.cuarentayocho_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 4)) / 48;
            me.sesenta_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 5)) / 60;
        },
        plan: function(valor) {
            var me = this;

            me.fireCotizador();
        },
        precio_venta: function(valor) {
            var me = this;
            switch (me.porcentajeEnganche) {
                case "10%":
                    me.enganche = me.precio_venta * 0.1;
                    break;
                case "20%":
                    me.enganche = me.precio_venta * 0.2;
                    break;
                case "25%":
                    me.enganche = me.precio_venta * 0.25;
                    break;
                case "40%":
                    me.enganche = me.precio_venta * 0.4;
                    break;
                case "45%":
                    me.enganche = me.precio_venta * 0.45;
                    break;
                case "60%":
                    me.enganche = me.precio_venta * 0.6;
                    break;
                case "65%":
                    me.enganche = me.precio_venta * 0.65;
                    break;
                case "80%":
                    me.enganche = me.precio_venta * 0.8;
                    break;
                case "85%":
                    me.enganche = me.precio_venta * 0.85;
                    break;
                default:
                    me.enganche = me.precio_venta * 0.2;
            }
            me.enganchereco = parseInt(me.precio_venta) * 0.25;
            if (!me.enganche) {
                me.enganche = me.enganchereco;
            }
            me.monto_financiar = me.precio_venta - me.enganche;
            me.doce_meses = (me.monto_financiar * (1 + me.tasadeinteres)) / 12;
            me.veinticuatro_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 2)) / 24;
            me.treintayseis_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 3)) / 36;
            me.cuarentayocho_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 4)) / 48;
            me.sesenta_meses =
                (me.monto_financiar * (1 + me.tasadeinteres * 5)) / 60;

            var mensualidadmaxima24 =
                (me.precio_venta -
                    me.precio_venta * 0.4 +
                    (me.precio_venta - me.precio_venta * 0.4) *
                        (me.tasadeinteres * 2)) /
                24;
            var mensualidadminima60 =
                (me.precio_venta -
                    me.precio_venta * 0.3 +
                    (me.precio_venta - me.precio_venta * 0.3) *
                        (me.tasadeinteres * 5)) /
                60;
        },
        porcentajeEnganche: function(value) {
            var me = this;
            switch (value) {
                case "10%":
                    me.enganche = me.precio_venta * 0.1;
                    break;
                case "20%":
                    me.enganche = me.precio_venta * 0.2;
                    break;
                case "25%":
                    me.enganche = me.precio_venta * 0.25;
                    break;
                case "40%":
                    me.enganche = me.precio_venta * 0.4;
                    break;
                case "45%":
                    me.enganche = me.precio_venta * 0.45;
                    break;
                case "60%":
                    me.enganche = me.precio_venta * 0.6;
                    break;
                case "65%":
                    me.enganche = me.precio_venta * 0.65;
                    break;
                case "80%":
                    me.enganche = me.precio_venta * 0.8;
                    break;
                case "85%":
                    me.enganche = me.precio_venta * 0.85;
                    break;
                default:
                    me.enganche = me.precio_venta * 0.2;
            }
        }
    },
    mounted() {
        this.fireCotizador();
    }
};
</script>
