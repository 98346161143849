/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;
import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);


import VueSlider from 'vue-slider-component';
window.VueSlider = require('vue-slider-component');

import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);

import VueCurrencyInput from 'vue-currency-input';

Vue.use(VueCurrencyInput);

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('cotizador', require('./components/cotizador-leasing.vue').default);
Vue.component('listado-autos-buscador2', require('./components/ListadoAutosBuscadorV2.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.Event = new Vue();

const app = new Vue({
    el: '#app2',

    data: {

    }
});

const swiper = new Swiper('.swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  autoHeight: true,
  speed: 1000,
  pauseOnMouseEnter: true,
  edgeSwipeThreshold: 20,
  longSwipesRatio: 0.1,
  effect: 'flip',
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  
  autoplay: {
      delay: 8000
  },
  
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});

const swiper2 = new Swiper('.swiper2', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  autoHeight: true,
  speed: 1000,
  pauseOnMouseEnter: true,
  edgeSwipeThreshold: 20,
  longSwipesRatio: 0.1,
  effect: 'flip',
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  
  autoplay: {
      delay: 8000
  },
  
  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});